<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div slot="buttonGroup" class="h-handle-button">
				<div class="h-b">
					<el-button type="primary" size="small" @click="addCustomerInfo" v-right-code="'Customerinfo:Add'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="setStopStatus" v-right-code="'Customerinfo:Setstatus'">停用</el-button>
				</div>
				<div class="h-b">
					<el-button type="primary" size="small" @click="setStatus" v-right-code="'Customerinfo:Setstatus'">启用</el-button>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="customerInfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange" highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName==='CustomerCode'"><span type="text" @click="rowClick(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.Utils.lazy(() => {
				var _this = this;
				this.Event.$on("reloadCustomerInfoPageList", () => this.reloadPageList());
				this.Event.$on("onAddCustomerInfo", () => this.addCustomerInfo());
			});
			this.initialize();
		},
		data() {
			return {
				isPassValidate: true,
				queryParam: {
					PageIndex: 1,
					PageSize: 10
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				multipleSelection: []
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/customerinfo/search", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			rowClick(row) {
				this.$ajax.send("omsapi/customerinfo/get", "get", {
					id: row.CustomerID
				}, (data) => {
					this.Event.$emit("clearEditCustomerInfoForm");
					data.Result.isFirstIn = true;

					if (data.Result.CustomerType) {
						data.Result.CustomerType = data.Result.CustomerType + '';
					}
					if (data.Result.IndustryType) {
						data.Result.IndustryType = data.Result.IndustryType + '';
					}
					console.log(data.Result);
					this.onChangeEditDataSource(data.Result);
				});
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
			addCustomerInfo() {
				this.$ajax.send("omsapi/customerinfo/getemptycustomer", "get", {}, (data) => {
					if (data.Result.CustomerType) {
						data.Result.CustomerType = data.Result.CustomerType + '';
					}
					if (data.Result.IndustryType) {
						data.Result.IndustryType = data.Result.IndustryType + '';
					}
					this.onChangeEditDataSource(data.Result);
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			setStopStatus(){
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`未选择数据.`, "error");
					return false;
				}
				var status = this.multipleSelection[0].CustomerStatus;
				if(status!=1){
					this.Utils.messageBox(`请选择启用数据.`, "error");
					return false;
				}
				
				var indexF = this.multipleSelection.findIndex(f => f.CustomerStatus != status);
				if (indexF > -1) {
					this.Utils.messageBox(`数据状态不统一.`, "error");
					return false;
				
				}
				var ids = this.Utils.selectionsToArr(this.multipleSelection, "CustomerID");
				this.$ajax.send("omsapi/customerinfo/setstatus", "post", ids, (data) => {
					this.initialize();
				
				});
			},
			setStatus() {
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`未选择数据.`, "error");
					return false;
				}
				var status = this.multipleSelection[0].CustomerStatus;
				if(status!=2){
					this.Utils.messageBox(`请选择停用数据.`, "error");
					return false;
				}
				var indexF = this.multipleSelection.findIndex(f => f.CustomerStatus != status);
				if (indexF > -1) {
					this.Utils.messageBox(`数据状态不统一.`, "error");
					return false;

				}
				var ids = this.Utils.selectionsToArr(this.multipleSelection, "CustomerID");
				this.$ajax.send("omsapi/customerinfo/setstatus", "post", ids, (data) => {
					this.initialize();

				});


			}
		}
	}
</script>
<style>
</style>